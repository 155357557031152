






























































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component } from "vue-property-decorator";

import {
  GoalsCard,
  GroupsCard,
  VideosCard,
  NewsCard,
  PresentationsCard,
} from "./components";

@Component({
  components: {
    PresentationsCard,
    GoalsCard,
    GroupsCard,
    VideosCard,
    NewsCard,
  },
})
export default class Dashboard extends Vue {
  @Getter("active") activeProfile!: boolean;
  @Getter("dashboard/loading") loading!: boolean;
  @Getter("dashboard/retrieved") retrieved!: boolean;
  @Action("dashboard/getData") getData!: () => Promise<any>;

  created() {
    if (!this.activeProfile) {
      this.$router.push("/company");
      return;
    }
    if (!this.retrieved) this.getData();
  }
}
