

































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";

import api from "@/core/utils/api";
import Pages from "@/core/utils/Pages";
import { HowToVideo } from "@/core/models";
import PagesRender from "@/components/common/PagesRender.vue";
import { VideoPlayer } from "@/components/common";

@Component({ components: { VideoPlayer, PagesRender } })
export default class HowToDash extends Vue {
  limit = 4;
  loading = false;
  videos: HowToVideo[] = [];
  pages: Pages<HowToVideo> = Pages.Empty();
  @Watch("videos", { immediate: true })
  videosChanged() {
    if (this.videos.length > 0)
      this.pages = new Pages<HowToVideo>(this.limit, this.videos);
  }

  async getVideos() {
    this.loading = true;
    try {
      const end = "/api/Showcase";
      const res = (await api.get(end)) as HowToVideo[];
      this.videos = res;
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }

  created() {
    this.getVideos();
  }
}
