













































































import Vue from "vue";
import { Getter } from "vuex-class";
import { Group, GroupVideo } from "@/core/models";
import { VideoPlayer } from "@/components/common";
import { Component, Watch } from "vue-property-decorator";
import { GroupName, PinnedVideo } from "@/core/store/groups/getters";

import Pages from "@/core/utils/Pages";
import PagesRender from "@/components/common/PagesRender.vue";

@Component({ components: { VideoPlayer, PagesRender } })
export default class VideosCard extends Vue {
  @Getter("groups/loading") loading!: boolean;
  @Getter("groups/getGroups") groups!: Group[];
  @Getter("groups/getNames") groupNames!: GroupName[];
  @Getter("groups/pinnedVideos") pinnedVideos!: PinnedVideo[];

  selectedGroupID = 0;
  get videos() {
    return this.groups
      .flatMap(x => x.Videos)
      .sort((a, b) => +b.UploadedAt - +a.UploadedAt);
  }
  getGroupName(id: number) {
    if (id === 0) return this.$t("groups.all").toString();
    const group = this.groupNames.find(g => g.id === id);
    return group?.name || "";
  }
  getIsPinned(g_id: number, v_id: number) {
    const group = this.pinnedVideos.find(g => g.id === g_id);
    if (!group) return false;
    return group.video === v_id;
  }

  get isAll() {
    return this.selectedGroupID === 0;
  }
  get selectedGroupName() {
    if (this.selectedGroupID === 0) return this.$t("groups.all").toString();
    return this.groupNames.find(g => g.id === this.selectedGroupID)?.name || "";
  }
  get videosList() {
    if (this.selectedGroupID === 0) return this.videos;
    return this.videos.filter(v => v.GroupID === this.selectedGroupID);
  }

  limit = 4;
  pages = Pages.Empty();
  @Watch("videosList", { immediate: true })
  videosChanged() {
    if (this.videosList.length > 0)
      this.pages = new Pages<GroupVideo>(this.limit, this.videosList);
    else this.pages = Pages.Empty();
  }
}
